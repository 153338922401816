import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import moment from "moment";

@Component({
  filters: {
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    shortDateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_SHORT_PATTER) : "";
    },
    decimal: (value: string) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(2)));
    },
    number: (value: number) => {
      return new Intl.NumberFormat(navigator.language).format(value);
    },
    price: (value: string, unit: string) => {
      unit = unit ? `${unit}` : "";
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      if (unit == "") {
        return `${result}`;
      } else if ((unit == "KG" || unit == "L") && value) {
        return `${unit} ${result}`;
      } else {
        return `${unit}`;
      }
    },
    unit: (value: string, simple: boolean) => {
      const prefix: string = simple ? "" : "al ";
      return value != null ? prefix + value.toLowerCase() : "";
    },
  },
})
export default class DfCardProductComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  product: DfProduct;
  @Prop({ type: String, default: null })
  promotionAlias: string;
  @Prop({ type: Boolean, default: false })
  brandMissingPreview: boolean;
  @Prop({ type: Boolean, default: false })
  showId: boolean;
  @Prop({ type: String, default: "P" })
  hashIdPrefix: string;
  @Prop({ type: String, default: null })
  context: string;
  @Prop({ type: String, default: "digital_flyer" })
  origin: string;

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get differentDiscountDate(): boolean {
    const rule01: boolean = !!this.productDateFrom && !!this.productDateTo;
    const rule02: boolean = this.currentPromotion.endDate !== this.productDateTo || this.currentPromotion.startDate !== this.productDateFrom;

    return rule01 && rule02;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get isSelected(): boolean {
    return this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription) > -1;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get discount(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING");
  }

  get discountPercentual(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL, "NUMBER");
  }

  get initialPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "NUMBER");
  }

  get finalPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "NUMBER");
  }

  get finalPriceForUnit(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT, "NUMBER");
  }

  get calculatedFinalPriceForUnit(): number {
    const rule01 = this.category === "BANCO SERVITO" || this.category === "CARNE" || this.category === "PESCE" || this.category === "ORTOFRUTTA";
    const rule02 = this.finalPriceForUnit === this.finalPrice;

    return rule01 && rule02 ? 0 : this.finalPriceForUnit;
  }

  get unitMisure(): string {
    const value = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_UNIT, "STRING");
    if (value == "HG") {
      return this.$t("df-measure-unit.hg.label").toString();
    } else if (!this.calculatedFinalPriceForUnit && value == "KG") {
      return this.$t("df-measure-unit.kg.label").toString();
    } else if (!this.calculatedFinalPriceForUnit && value == "L") {
      return this.$t("df-measure-unit.l.label").toString();
    } else {
      return value;
    }
  }

  get category(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CATEGORY, "STRING");
  }

  get points(): number {
    const points: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_POINTS, "NUMBER");
    return points ? this.$options.filters!.number(points) : null;
  }

  get contributionPoints(): number {
    const contributionPoints: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_POINTS, "NUMBER");
    return contributionPoints ? this.$options.filters!.number(contributionPoints) : null;
  }

  get contributionPrice(): number {
    const contributionPrice: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_PRICE, "NUMBER");

    return contributionPrice ? this.$options.filters!.price(contributionPrice) : null;
  }

  get brandLogoImageUrl(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get previewUrl(): string {
    return Utils.getPropertyImageUrl(this.product, Utils.PROPERTY_PRODUCT_PREVIEW, null);
  }

  get brandLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_BRAND_LOGOS);
  }

  get propertyLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_PROPERTY_LOGOS);
  }

  get missingPreviewUrl(): string {
    return this.brandMissingPreview ? this.brandLogoImageUrl : this.previewUrl;
  }

  get productDateFrom(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_FROM, "DATE");
  }

  get productDateTo(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_TO, "DATE");
  }

  get startDate(): string {
    const startDate: string = this.productDateFrom ? this.productDateFrom : this.currentPromotion.startDate;
    return startDate ? this.$options.filters!.shortDateFormat(startDate) : null;
  }

  get endDate(): string {
    const endDate: string = this.productDateTo ? this.productDateTo : this.currentPromotion.endDate;
    return endDate ? this.$options.filters!.shortDateFormat(endDate) : null;
  }

  get ecommerceEnabled(): boolean {
    const storeProperty: any = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_ECOMMERCE_ENABLED, "BOOLEAN");
    const brandProperty: any = Utils.getPropertyValue(this.brand, Utils.PROPERTY_ECOMMERCE_ENABLED, "BOOLEAN");

    return storeProperty !== null ? !!storeProperty : !!brandProperty;
  }

  get ecommerceUrl(): string {
    const url: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_URL_ECOMMERCE_SELEX, "STRING");
    return url ? `https://${url}` : null;
  }

  get buttonEcommerceIcon(): string {
    return `${Utils.getPublicPath()}/assets/ecommerce.png`;
  }

  get productDescription(): string {
    const forceMaiuscole: boolean = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_FORCE_UPPERCASE_PRODUCT_DESCRIPTION, "BOOLEAN", false);
    const description: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");

    const productDescription: string = description || longDescription || this.product.description || "";
    return forceMaiuscole ? productDescription.toUpperCase() : productDescription;
  }

  private openNewPage() {
    window.open(this.ecommerceUrl, "_blank");
  }

  private toggleSelection() {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription);
    if (index === -1) {
      this.shoppingList = [new DfShoppingProduct(this.productDescription, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_to_wishlist",
        product: this.productDescription.replace(/[\n\r]/g, " "),
        origin: this.origin,
      });
    } else {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private setAnaliticsTraker() {
    Utils.setAnaliticsTraker({
      event: "click_product",
      store: this.currentStore.name,
      promo_name: this.currentPromotion.description,
      product: this.productDescription.replace(/[\n\r]/g, " "),
      context: this.context,
      origin: this.origin,
    });
  }
}
